<template>
    <div>
        <v-dialog v-model="$store.state.dialog" width="800" persistent="">
            <v-card flat>
                <v-toolbar color="primary" dark>
                    <v-toolbar-title>
                        {{
                            $store.state.bodyDetails.dialogType == "add"
                                ? "اضــافة"
                                : "تعديل"
                        }}
                        طابق
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" color="error">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-col>
                    <v-form
                        @submit.prevent="submit()"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" md="6" class="pl-2">
                                <small class="font-weight-bold">
                                    رمز الطابق
                                    <span class="red--text">
                                        * يجب ان يكون فريدة من نوعةا
                                    </span>
                                </small>
                                <v-text-field
                                    v-model="body.floorCode"
                                    :rules="[required()]"
                                    required
                                    outlined
                                    dense
                                    color="secondary"
                                    filled
                                    placeholder="رمز الطابق"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" class="pl-2">
                                <small class="font-weight-bold">
                                    اسم الطابق
                                    <span class="red--text font-weight-bold"
                                        >*</span
                                    >
                                </small>
                                <v-text-field
                                    v-model="body.floorName"
                                    :rules="[required()]"
                                    required
                                    outlined
                                    dense
                                    color="secondary"
                                    filled
                                    placeholder="اسم الطابق"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="12" class="my-4">
                                <v-btn
                                    type="submit"
                                    :disabled="
                                        !body.floorName || !body.floorCode
                                    "
                                    :loading="loading"
                                    x-large
                                    color="primary"
                                    >حفظ</v-btn
                                >
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2"
                                    outlined
                                    >الغاء</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import validations from "@/utils/validations";
import { mapState } from "vuex";
export default {
    components: {},

    data: () => ({
        valid: true,
        body: {},
        loading: false,
        ...validations
    }),

    computed: {
        ...mapState(["bodyDetails"])
    },

    created() {
        this.body = {};
    },

    mounted() {
        this.$eventBus.$on("fill-fields", () => {
            this.body = this.bodyDetails;
        });
    },

    methods: {
        submit() {
            if (this.bodyDetails.dialogType == "add") {
                this.addItem();
            } else {
                this.editItem();
            }
        },
        addItem() {
            this.loading = true;
            this.$GenericService
                .create("floor", this.body)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم الإضافة بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    // console.log("error" + err.data.status + err.data.message)
                    if (err.data.status === "404.4") {
                        this.$store.dispatch("setSnackbar", {
                            text: `رمز الطابق ( ${this.body.floorCode} ) بالفعل ةو موجود `,
                            color: "error"
                        });
                    } else {
                        this.$store.dispatch("setSnackbar", {
                            text: ` ${err.data.message}`,
                            color: "error"
                        });
                    }
                })
                .finally(() => (this.loading = false));
        },

        editItem() {
            this.loading = true;
            const data = Object.entries(this.body).reduce(
                (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                {}
            ); //remove null fields

            this.$GenericService
                .update(`floor/edit/${this.body.id}`, data)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم التعديل بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.response.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        close() {
            this.$store.dispatch("setDialog");
            this.body = {};
            this.resetValidation();
            this.$store.dispatch("setBodyDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
};
</script>
